import './App.css';
import Looper from './logic/LooperLogic';
import MenuScreen from './screens/MenuScreen';
import { BrowserRouter, Routes, Route, useLocation, Navigate } from 'react-router-dom';
import { Amplify } from 'aws-amplify';
import { getCurrentUser } from 'aws-amplify/auth';
import '@aws-amplify/ui-react/styles.css';
import awsconfig from './aws-exports';
import { useEffect, useState } from 'react';
import { Authenticator } from '@aws-amplify/ui-react';

import { I18n } from 'aws-amplify/utils';
import { translations } from '@aws-amplify/ui-react';


I18n.putVocabularies(translations);
I18n.setLanguage('ja');

I18n.putVocabularies({
  ja: {
    'Nickname': 'ニックネーム',
    'Enter your Nickname': "ニックネームを入力",
  },
});

const VERSION = "v0.3.0"


Amplify.configure(awsconfig);

function ReloadLoopingRange() {
  const location = useLocation()
  return <Navigate to='/loopingRange' replace={true} state={location.state}/>
}

function WithVersionNum(Component) {
  return () => (
    <div>
      <Component/>
      <div className='fixed top-0 right-1 z-50 text-gray-500 text-right'>Pre-alpha<br/>Semantic version: {VERSION}</div>
    </div>
  )
}

async function checkCredentials(setUser, setManualLogin) {
  try {
    const res = await getCurrentUser()
    setUser(res)
    setManualLogin(true)
  } catch (e) {
    // console.error(e)
    // error occurs if user not logged in
  }
}

function App() {
  const [checkLogin, setCheckLogin] = useState(false)
  const [_user, setUser] = useState({ userId: "guest", username: "guest" })
  useEffect(() => { checkCredentials(setUser, setCheckLogin); }, [])

  if (!checkLogin) {
    return (
      <BrowserRouter>
          <Routes>
            <Route exact path='/' element={<MenuScreen user={_user} replace={true} setManualLogin={setCheckLogin}/>}/>
            <Route exact path='/loopingRange' element={<Looper user={_user}/>}/>
            <Route exact path='/loopingRangeReload' element={<ReloadLoopingRange/>} />
          </Routes>
        </BrowserRouter>
    );
  } else {
    return (
      <div className='fixed h-full w-full flex items-center justify-items-center'>
        <Authenticator className='flex-1'>
          {({ signOut, user }) => (
          <main>
          <BrowserRouter>
            <Routes>
              <Route exact path='/' element={<MenuScreen user={user} replace={true}/>}/>
              <Route exact path='/loopingRange' element={<Looper user={user}/>}/>
              <Route exact path='/loopingRangeReload' element={<ReloadLoopingRange/>} />
            </Routes>
          </BrowserRouter>
          </main>)}
        </Authenticator>
      </div>
    );
  }
}

export default WithVersionNum(App);