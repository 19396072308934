/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getPhrases = /* GraphQL */ `
  query GetPhrases($rating: Int) {
    getPhrases(rating: $rating)
  }
`;
export const getRhythmSection = /* GraphQL */ `
  query GetRhythmSection($id: ID!) {
    getRhythmSection(id: $id) {
      id
      name
      key
      tempo
      phrases {
        nextToken
        startedAt
        items {
          id
          data
        }
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listRhythmSections = /* GraphQL */ `
  query ListRhythmSections(
    $filter: ModelRhythmSectionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRhythmSections(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        key
        tempo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncRhythmSections = /* GraphQL */ `
  query SyncRhythmSections(
    $filter: ModelRhythmSectionFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncRhythmSections(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        key
        tempo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getPhrase = /* GraphQL */ `
  query GetPhrase($id: ID!) {
    getPhrase(id: $id) {
      id
      data
      noteCount
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      rhythmSectionPhrasesId
      __typename
    }
  }
`;
export const listPhrases = /* GraphQL */ `
  query ListPhrases(
    $filter: ModelPhraseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPhrases(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        data
        noteCount
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        rhythmSectionPhrasesId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncPhrases = /* GraphQL */ `
  query SyncPhrases(
    $filter: ModelPhraseFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncPhrases(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        data
        noteCount
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        rhythmSectionPhrasesId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getUserData = /* GraphQL */ `
  query GetUserData($id: ID!) {
    getUserData(id: $id) {
      id
      nickname
      userId
      rating
      imageKey
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listUserData = /* GraphQL */ `
  query ListUserData(
    $filter: ModelUserDataFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserData(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        nickname
        userId
        rating
        imageKey
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncUserData = /* GraphQL */ `
  query SyncUserData(
    $filter: ModelUserDataFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncUserData(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        nickname
        userId
        rating
        imageKey
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
