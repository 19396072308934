import { useState } from 'react';
import { ReactComponent as MIDIIcon } from '../res/img/MIDI_input.svg';
import { ReactComponent as MicIcon } from '../res/img/mic_icon.svg';

function Loading(props) {
  return (
  <div className="absolute w-1/2 h-1/2 -ml-9 -mt-9 flex justify-center items-center z-50">
    <div className="animate-ping h-4 w-4 bg-white rounded-full"></div>
    <h1 className='text-white ml-4'>ロード中...</h1>
  </div>)
}

function Loaded(props) {
  const [ micBoxBorder, setMicBoxBorder ] = useState('black');
  const [ MIDIBoxBorder, setMIDIBoxBorder ] = useState('black');
  return (
    <div className='flex-1 flex flex-col'>
      <div
        style={{
            display: 'flex',
            flexDirection: 'row',
            flex: 1,
            gap: 12,
        }}>
        <div
          onClick={() => props.onClick(1)}
          onMouseEnter={() => setMIDIBoxBorder('grey')}
          onMouseLeave={() => setMIDIBoxBorder('black')}
          style={{
              flex: 1,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              background: 'white',
              borderRadius: 16,
              gap: 12,
              borderWidth: 4,
              borderStyle: 'solid',
              borderColor: MIDIBoxBorder
          }}>
          <h1 style={{ fontSize: 36, margin: 0 }}>MIDIデバイスを<br/>接続</h1>
          <MIDIIcon style={{ width: 48, height: 48 }}/>
        </div>
        <div
          onClick={() => props.onClick(2)}
          onMouseEnter={() => setMicBoxBorder('grey')}
          onMouseLeave={() => setMicBoxBorder('black')}
          style={{
              flex: 1,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              background: 'white',
              borderRadius: 16,
              gap: 12,
              borderWidth: 4,
              borderStyle: 'solid',
              borderColor: micBoxBorder
          }}>
          <h1 style={{ fontSize: 36, margin: 0 }}>マイクを使う</h1>
          <MicIcon style={{ width: 48, height: 48 }}/>
        </div>
      </div>
      {/* <h1 style={{ color: 'white', margin: 0, fontSize: 16 }}>*There may be some problems including input delay when using mic.</h1> */}
    </div>
  )
}

function InputSelectionOverlay (props) {
    return (
        <div
          className='font-bold'
          style={{
              position: 'fixed',
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              zIndex: 5,
              background: 'rgba(0, 0, 0, 0.6)',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'}}>
         
          <div
          style={{
              width: '50%',
              height: '50%',
              background: '#000000',
              display: 'flex',
              flexDirection: 'column',
              gap: 12,
              padding: 36,
              alignContent: 'center',
              textAlign: 'center',
              borderRadius: 16
          }}>
            {!props.loaded ? <Loading/> : <Loaded onClick={props.onClick}/>}
            </div>
          </div>
    );
}

export default InputSelectionOverlay;
