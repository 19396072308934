import { Component, createRef } from 'react';
import './CircularProgressBar.css'
// distance from eye to display / distance from eye to eye to floating component (which is closer to the eye than the display - 1.0
// const FLOAT_RATIO = 0.1;  

class CircularProgressBar extends Component {
    progressCircle = createRef()
    progressCircleShadow = createRef()

    constructor(props) {
      super();
      this.state = {
        pulsate: false,
        pulsateOverwrite: false,
      }
    }

    pulsate(color) {
      this.setState({ 
        pulsate: false,
        pulsateOverwrite: color
       })

      setTimeout(() => {
        this.setState({ 
          pulsate: color,
          pulsateOverwrite: false
        })
      }, 10)
    }

    render() {

      let {
        size = 150,
        // progress = 0,
        trackWidth = 50,
        indicatorCap = `round`
      } = this.props
    
      const radius = size / 2 - trackWidth
            // radiusBig = radius + 40,
            // dashArray = 2 * Math.PI * radius,
            // dashOffset = dashArray * ((100 - progress) / 100),
            // dashArrayBig = 2 * Math.PI * radiusBig
    
            
      let animationHashMap = {
        'purple': 'circularProgressBarPurple 1s linear',
        'green': 'circularProgressBarGreen 1s linear',
        'red': 'circularProgressBarRed 1s'
      }

      const innerTextComp = (text) => (<div style={{
        width: '100%',
        height: '100%',
        textAlign: 'center',
        fontWeight: 'bolder',
        color: '#B5B5B5',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: 72
      }}>{text}</div>)

      let innerText = null
      if (this.props.skipping) 
        innerText = innerTextComp('スキップ中...')
      else {
        if (this.props.guess[0] === this.props.guess[1] && this.props.guess[0] !== 0)
          innerText = innerTextComp('正解！')
        else 
          innerText = (
          <>
            <div style={{
                position: 'absolute',
                right: '50%',
                bottom: 'calc(50% - 30px)',
                color: '#545454',
                zIndex: 2,
                fontSize: 200,
                height: '500',
                width: '500'
            }}>{this.props.guess[0]}</div>
            <div style={{
                position: 'absolute',
                left: 'calc(50% + 30px)',
                top: 'calc(50% + 30px)',
                transform: "translate(-50%, -50%) rotate(-30deg)",
                zIndex: 2,
                borderTop: 'solid 10px #545454',
                width: 250,
                height: 0
            }}/>
            <div style={{
                position: 'absolute',
                left: 'calc(50% + 60px)',
                top: 'calc(50% + 60px)',
                color: '#545454',
                zIndex: 2,
                fontSize: 100,
                marginTop: '10%',
                margin: 0
            }}>{this.props.guess[1]}</div>
          </>)
      }

      return (
        <>
        <style>
          {`@keyframes progressShadow {
            from {
              stroke-dashoffset: calc(${radius}px * 2 * 3.1415);
            }
            to {
              stroke-dashoffset: 0;
            }
          }
          progressShadowAnimation {
            animation: cPGInner 1s
          }
          progressAnimationPurple {
            animation: circularProgressBarPurple 1s linear
          }
          `
          }
        </style>
        <div 
          style={{
            ...this.props.style
          }}>
            {innerText}
          </div>
          <div
            ref={this.progressCircle}
            style={{ 
              animation: (this.state.pulsate && this.state.pulsate !== 'red') ? animationHashMap[this.state.pulsate]: '',
              ...this.props.style,
              stroke: '#ffffff',
              ...this.state.pulsateOverwrite ? {
                width: '700px',
                height: '700px',
                stroke: this.state.pulsateOverwrite === 'purple' ? '#FF00B8' : 
                (this.state.pulsateOverwrite === 'green' ? '#2BC305' : '#FF3232')
              } : {},
            }}
          >
            <svg
              style={{ width: '100%', height: '100%', overflow: 'visible', transform: 'rotate(-90deg)' }}
            >
              <circle
                ref={this.progressCircleShadow}
                cx='50%'
                cy='50%'
                fill="transparent"
                r={radius}
                stroke='#545454'
                strokeWidth={50}
                strokeDasharray={radius * 2 * Math.PI}
                strokeLinecap={indicatorCap}
                filter={'drop-shadow(0px 0px 16px #00000040)'}
                style={{
                  animationName: `progressShadow${this.state.pulsate ? ', cPGInner' : ''}`,
                  animationDuration: `${this.props.loopLen}, 1s`,
                  animationTimingFunction: 'linear, ease',
                  animationPlayState: this.props.initialized ? 'running' : 'paused',
                  ...this.state.pulsateOverwrite ? {
                    filter: 'drop-shadow(0px 0px 16px #00000040)'
                  } : {},
                }}
              />
              <circle
                cx='50%'
                cy='50%'
                fill="transparent"
                r={radius}
                stroke='#545454'
                strokeWidth={50}
              />
              <g style={{ animation: `${(this.state.pulsate === 'red') ? `${animationHashMap[this.state.pulsate]}` : ''}` }}>
              <circle
                cx='50%'
                cy='50%'
                fill="transparent"
                r={'50%'}
                strokeDasharray={`calc(50% * 2 * ${Math.PI})`}
                strokeLinecap={indicatorCap}
                style={{
                  animationName: 'progress',
                  animationDuration: `${this.props.loopLen}s`,
                  animationTimingFunction: 'linear',
                  animationPlayState: this.props.initialized ? 'running' : 'paused',
                  strokeWidth: 50,
                  ...this.props.skipping ? {stroke: '#B5B5B5'} : {},
                }}
              />
              </g>
            </svg>
          </div>
        </>
      )
    }
}

export default CircularProgressBar;
