/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createRhythmSection = /* GraphQL */ `
  mutation CreateRhythmSection(
    $input: CreateRhythmSectionInput!
    $condition: ModelRhythmSectionConditionInput
  ) {
    createRhythmSection(input: $input, condition: $condition) {
      id
      name
      key
      tempo
      phrases {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateRhythmSection = /* GraphQL */ `
  mutation UpdateRhythmSection(
    $input: UpdateRhythmSectionInput!
    $condition: ModelRhythmSectionConditionInput
  ) {
    updateRhythmSection(input: $input, condition: $condition) {
      id
      name
      key
      tempo
      phrases {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteRhythmSection = /* GraphQL */ `
  mutation DeleteRhythmSection(
    $input: DeleteRhythmSectionInput!
    $condition: ModelRhythmSectionConditionInput
  ) {
    deleteRhythmSection(input: $input, condition: $condition) {
      id
      name
      key
      tempo
      phrases {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createPhrase = /* GraphQL */ `
  mutation CreatePhrase(
    $input: CreatePhraseInput!
    $condition: ModelPhraseConditionInput
  ) {
    createPhrase(input: $input, condition: $condition) {
      id
      data
      noteCount
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      rhythmSectionPhrasesId
      __typename
    }
  }
`;
export const updatePhrase = /* GraphQL */ `
  mutation UpdatePhrase(
    $input: UpdatePhraseInput!
    $condition: ModelPhraseConditionInput
  ) {
    updatePhrase(input: $input, condition: $condition) {
      id
      data
      noteCount
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      rhythmSectionPhrasesId
      __typename
    }
  }
`;
export const deletePhrase = /* GraphQL */ `
  mutation DeletePhrase(
    $input: DeletePhraseInput!
    $condition: ModelPhraseConditionInput
  ) {
    deletePhrase(input: $input, condition: $condition) {
      id
      data
      noteCount
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      rhythmSectionPhrasesId
      __typename
    }
  }
`;
export const createUserData = /* GraphQL */ `
  mutation CreateUserData(
    $input: CreateUserDataInput!
    $condition: ModelUserDataConditionInput
  ) {
    createUserData(input: $input, condition: $condition) {
      id
      nickname
      userId
      rating
      imageKey
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateUserData = /* GraphQL */ `
  mutation UpdateUserData(
    $input: UpdateUserDataInput!
    $condition: ModelUserDataConditionInput
  ) {
    updateUserData(input: $input, condition: $condition) {
      id
      nickname
      userId
      rating
      imageKey
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteUserData = /* GraphQL */ `
  mutation DeleteUserData(
    $input: DeleteUserDataInput!
    $condition: ModelUserDataConditionInput
  ) {
    deleteUserData(input: $input, condition: $condition) {
      id
      nickname
      userId
      rating
      imageKey
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
