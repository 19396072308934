import './ResultsComponent.css'
import { ReactComponent as ArrowIcon } from '../res/img/arrow.svg';
import { ReactComponent as RepeatIcon } from '../res/img/repeat.svg';
import { generateClient } from 'aws-amplify/api';
import { updateUserData } from '../graphql/mutations';
import { useEffect, useState } from 'react';
import { listRhythmSections, listUserData } from '../graphql/queries';
import { useNavigate } from 'react-router-dom';
import ProfPicComponent from './ProfPicComponent';

const client = generateClient()

async function getUserData(username, callback) {
  let variables = { filter: {userId: {eq: username}} }
  let userInfo = await client.graphql({ query: listUserData , variables} );
  callback(userInfo.data.listUserData.items[0])
}

const rateUpdate = async (guesses, userInfo, setRateShift) => {
  try {
    let rateShift = 0;
    guesses.forEach(guess => {
      if (guess[0] === guess[1])
        rateShift += 1000
      else
        rateShift -= 1000
    })
    setRateShift(rateShift)
    await client.graphql({ query: updateUserData, variables: { input: {id: userInfo.id, rating: userInfo.rating + rateShift, _version: userInfo._version} }})
  } catch (e) {
    console.log(e)
  }
}

function TextComponent(props) {
  if (props.mode === 1) {
    return(
      <div className='flex-1'>
        <div className='text-3xl pr-4 text-end' style={{ color: (props.rateShift > 0) ? '#12E100' : '#FF3232' }}>{(props.rateShift > 0) ? '+' : ''}{props.rateShift}</div>
        <div className='text-[96px] font-light leading-none text-end' style={{ fontSize: 96, fontWeight: 'light' }}>{props.oldRating + props.rateShift}</div>
      </div>
    )
  } else {
    return (
      <div className='flex-1'>
        <div className='text-[96px] font-light leading-none text-end' style={{ fontSize: 72, fontWeight: 'light' }}>Good Job!</div>
      </div>
    )
  }
}

async function getNextRhythmSection(setNextRS) {
  const client = generateClient()
  let rhythmSectionsData = await client.graphql({ query: listRhythmSections });
  let rhythmSections = rhythmSectionsData.data.listRhythmSections.items
  setNextRS(rhythmSections[Math.floor(Math.random() * rhythmSections.length)])
}

function ResultsComponent(props) {
  const [oldRating, setOldRating] = useState()
  const [rateShift, setRateShift] = useState(0)
  const [userData, setUserData] = useState()
  const navigate = useNavigate()

  const [nextRS, setNextRS] = useState(props.rhythmSection)
  
  useEffect(() => {
    if (!userData) {
      getUserData(props.user.username, data => {
        setUserData(data)
        setOldRating(data.rating)
        rateUpdate(props.guesses, data, setRateShift)
        if (props.mode === 1) getNextRhythmSection(setNextRS)
      })
    }
  }, [userData]);

  return (
      <div style={{ 
          position: 'fixed', 
          top: '45%',
          left: '50%',
          transform: "translate(-50%, -50%)",
          zIndex: 50,
          background: '#474747',
          borderRadius: 64,
          animation: 'slideIn 1s'
          }}
          className='shadow-xl'>
            <div className='flex space-between text-white items-center m-8 pb-8 border-b-4 border-gray-400'>
              <ProfPicComponent className='rounded-full mr-8 w-24 h-24' imgKey={"boy_5664044"}/>
              <TextComponent mode={props.mode} rateShift={rateShift} oldRating={oldRating}/>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center'}}>
              <div style={{ position: 'aboslute', bottom: 0, right: 0, display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', padding: 20 }}>
                <div style={{ background: 'white', width: 120, height: 70, margin: 16, borderRadius: 50, position: 'relative' }} >
                  <RepeatIcon 
                    style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }} 
                    onClick={() => props.cleanup() && navigate('/loopingRangeReload', { 
                      state: {
                        rhythmSection: nextRS,
                        keyShift: 0,
                        tempo: 120,
                        userData: props.userData,
                        mode: props.mode
                      }})}/>
                </div>
                <div style={{ background: 'white', width: 120, height: 70, margin: 16, borderRadius: 50, position: 'relative' }}>
                  <ArrowIcon 
                    style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}
                    onClick={() => navigate('/')}/>
                </div>
              </div>
            </div>
            
      </div>
  )
}

export default ResultsComponent