import { getUrl } from "aws-amplify/storage"
import { useEffect, useState } from "react"

async function getProfUrl(key, setUrl) {
    if (key.slice(0, 12) !== "prof_images/") 
        key = "prof_images/" + key
    if (key.slice(key.length - 4, key.length) !== ".png")
        key = key + ".png"
    const result = await getUrl({
        key: key,
        options: {
          validateObjectExistence: true, // defaults to false
        }
    })
    setUrl(result.url.href)
}

function ProfPicComponent(props) {
    const [imgUrl, setImgUrl] = useState()

    useEffect(() => {
        if (props.imgKey) getProfUrl(props.imgKey, setImgUrl)
    }, [props.imgKey])

    if (!props.imgKey || !imgUrl) {
        return (
            <div onClick={props.onClick} className={props.className}/>
        )
    } else {
        return (
            <img onClick={props.onClick} className={props.className} alt='prof pic' src={imgUrl} width={80} height='100%'/>
        )
    }
}

export default ProfPicComponent;