// import { generate graphqlOperation } from "aws-amplify";
import { generateClient } from "aws-amplify/api";
import { getPhrases, listPhrases, listUserData } from "../graphql/queries";

const client = generateClient()

export async function getPhrasesByRating(rating, callback) {
    let phrasesRaw;
    try {
        // phrasesRaw = await API.graphql(graphqlOperation(getPhrases, { rating }));
        phrasesRaw = await client.graphql({ query: getPhrases, variables: {  rating } });
    } catch (e) {
        console.log(e.errors[0]);
    }
    phrasesRaw = JSON.parse(JSON.parse(phrasesRaw.data.getPhrases).body)
    // let phrases = phrasesRaw.map(phrase_ => JSON.parse(phrase_.data).phrase)
    let phrases = []
    phrasesRaw.forEach(phrase_ => {
        if (phrase_) phrases.push(JSON.parse(phrase_.data).phrase)
    })
    callback(phrases)
    console.log("Phrases loaded: ");
    console.log(phrases);
}

export async function getPhrasesForPractise(source, callback) {
    let queryCount = 0
    let phrases = []
    let nextToken = null
    while (queryCount < 4) {
        queryCount += 1
        let phrasesRaw;
        try {
            phrasesRaw = await client.graphql({ query: listPhrases, 
                variables: { 
                    filter: {source: {eq: source}},
                    limit: 1000,
                    ...(nextToken) && { nextToken }
                } });
        } catch (e) {
            console.log(e.errors[0]);
        }
        console.log(phrasesRaw)
        nextToken = phrasesRaw.data.listPhrases.nextToken
        phrasesRaw = phrasesRaw.data.listPhrases.items
        // phrasesRaw = JSON.parse(JSON.parse(phrasesRaw.data.listPhrases).body)
        // let phrases = phrasesRaw.map(phrase_ => JSON.parse(phrase_.data).phrase)
        phrasesRaw.forEach(phrase_ => {
            if (phrase_) phrases.push(JSON.parse(phrase_.data).phrase)
        })
    }
    console.log(phrases)
    callback(phrases)
    console.log("Phrases loaded: ");
    console.log(phrases);
}

// DEPRECATED
export async function retrievePhrases(userRating, callback) {
    let phrasesRaw;
    try {
        phrasesRaw = await client.graphql({ query: listPhrases });
    } catch (e) {
        console.log(e.errors[0]);
    }
    phrasesRaw = phrasesRaw.data.listPhrases.items
    phrasesRaw = phrasesRaw.filter(item => !item._deleted)
    let phrases = [];
    phrasesRaw.forEach((phrase) => {
        phrases.push(JSON.parse(phrase.data).phrase);
    });


    // randomizing order of phrases with Fisher-Yates
    let currentIndex = phrases.length, randomIndex;
    while (currentIndex !== 0) {
        randomIndex = Math.floor(Math.random() * currentIndex--);
        [phrases[currentIndex],  phrases[randomIndex]] = [phrases[randomIndex], phrases[currentIndex]];
        [phrasesRaw[currentIndex],  phrasesRaw[randomIndex]] = [phrasesRaw[randomIndex], phrasesRaw[currentIndex]];
    }

    phrases = phrases.slice(0, 10)
    callback(phrases)
    console.log("Phrases loaded: ");
    console.log(phrases);
}

export async function getUserInfo(id, callback) {
    try {
        let userInfo = await client.graphql({ query: listUserData, variables: { filter: {userId: {eq: id}} } });
        userInfo = userInfo.data.listUserData.items[0]
        callback(userInfo)
    } catch (e) {
        console.log(e)
    }
}