import React, { Component  } from 'react';
import Keyboard from '../components/KeyboardComp';
import InputSelectionOverlay from '../components/InputSelectionOverlay';
import * as Tone from 'tone';
import { ReactComponent as MIDIIcon } from '../res/img/MIDI_input.svg';
import { ReactComponent as EarIcon } from '../res/img/ear.svg';
// import { API, graphqlOperation } from 'aws-amplify';
// import { getRhythmSection, listPhrases } from '../graphql/queries';
// import FloatingComp from '../components/FloatingComp';
// import CircularProgressBar from '../components/CircularProgressBar';
import '../components/CircularProgressBar.css'
// import { ReactComponent as StopIconStroke } from '../res/img/stop_stroke.svg';
// import { ReactComponent as VolumeIconStroke } from '../res/img/volume_stroke.svg';
// import { ReactComponent as PauseIconStroke } from '../res/img/pause_stroke.svg';
import { ReactComponent as SkipIconStroke } from '../res/img/skip_stroke.svg';
// import { ReactComponent as StopIconFill } from '../res/img/stop_fill.svg';
// import { ReactComponent as VolumeIconFill } from '../res/img/volume_fill.svg';
// import { ReactComponent as PauseIconFill } from '../res/img/pause_fill.svg';
import { ReactComponent as SkipIconFill } from '../res/img/skip_fill.svg';
import SVGButton from '../components/SVGButton';
// import ResultsComponent from '../components/ResultsComponent';
import '../components/ResultsComponent.css';
import { useLocation } from 'react-router-dom';
import LooperComponent from '../components/LooperComponent';
import { getPhrasesByRating, getPhrasesForPractise } from '../api/APICalls';
import { getUrl } from 'aws-amplify/storage';
import { getRhythmSection } from '../graphql/queries';
import { generateClient } from 'aws-amplify/api';
// import FrequencyChart from '../components/FrequencyChart';

const NOTE_NAMES = [ "C", "C#", "D", "D#", "E", "F", "F#", "G", "G#", "A", "A#", "B" ];

const READY_PHASE = 0;
const LISTEN_PHASE = 1;
const GUESS_PHASE = 2;

const GUESS_WINDOW = 0.2;

class LooperLogic extends Component {

    ProgressBar = React.createRef()
    phraseIndex = 0
    synth = null

    midi = null
    phrases = null
    phraseIds = null
    phraseLoop = null
    rhythmSectionRaw = null

    inputMIDIEvents = []

    inputMode = 0

    static defaultProps = {
        loopLen: "2m",
    }
    
    constructor(props) {
        super();
        this.state = {
            results: false,
            loopNum: 0,
            skipping: false,
            phase: READY_PHASE,
            guessLog: [],
            guess: [0, 0],
            firstKey: null,
            loadedRhythmSection: false,
            loadedPhrases: false,
            currentNote: 0,
            consecutiveCount: 0,
            mute: false
        }
    }

    async getRhythmSectionRaw() {
        try {
            const result = await getUrl({
                key: "rhythm_section/" + this.props.location.state.rhythmSection.name + ".wav",
                options: {
                  validateObjectExistence: true, // defaults to false
                }
            })
            // console.log('signed URL: ', result.url.href);
            // console.log('URL expires at: ', result.expiresAt);
            this.rhythmSectionRaw = new Tone.Player(result.url.href, () => this.setState({loadedRhythmSection: true})).toDestination()
        } catch (error) {
            console.error(error)
        }
        // this.rhythmSectionRaw = new Tone.Player("https://loop7th-storage-fb0118d5104611-main.s3.ap-northeast-1.amazonaws.com/sample.wav")
        // this.rhythmSectionRaw.autostart = true
    }

    componentDidMount() {
	    // getPhrasesByRating(this.props.location.state.userData.rating, phrases => { this.phrases = phrases; this.setState({loadedPhrases: true}) });
        // fetching phrases for RS
        const client = generateClient()
        client.graphql({ 
            query: getRhythmSection, 
            variables: {
                id: this.props.location.state.rhythmSection.id
            }}).then(res => { 
                console.log(res.data.getRhythmSection.phrases.items)
                this.phrases = res.data.getRhythmSection.phrases.items.map(e => JSON.parse(e.data)['phrase'])
                this.setState({loadedPhrases: true}) 
            })
        // this.retrieveRhythmSection()

        this.getRhythmSectionRaw()
    }

    componentWillUnmount() {
        if (this.rhythmSectionRaw) this.rhythmSectionRaw.stop()
    }

    consoleOutputPlayedNotes(time, midiEvents) {
        // outputting played notes in format usable as phrase data in graphql
        let notes = []
        let notesStart = {};
        midiEvents.forEach((event, index) => {
            if (event[0] === 144) {
                notesStart[event[1]] = event[3]
            } else if (event[0] === 128) {
                if (event[1] in notes) {
                    notes.push([event[1], event[3] - notesStart[event[1]], notesStart[event[1]] - time])
                    delete notesStart[event[1]]
                } else {
                    notes.push([event[1], event[3] - time, 0])
                }
            }
        })
    }

    evaluateNoteGuess(note) {
        console.log('Evaluating note')
        let time = Tone.Transport.now()
        let startTime = time -  Tone.Time(note[2]).toSeconds() - GUESS_WINDOW;
        let endTime = startTime + Tone.Time(note[2]).toSeconds();

        let same_notes = this.inputMIDIEvents.filter(e => 
            e[1] === note[0] + this.props.location.state.rhythmSection.key)
        console.log("Same notes: ", same_notes.toString())
        console.log("StartTime: ", startTime, ", EndoTime: ", endTime)
        let within_window = same_notes.filter(e => startTime - GUESS_WINDOW <= e[3] && e[3] <= endTime + GUESS_WINDOW)
        console.log("Notes within window", within_window.toString())
        return within_window.length > 0
        // let totalTime = 0
        // let noteStarted = -1
        // within_window.forEach(e => {
        //     if (e[0] === 144) {
        //         if (noteStarted <= 0) {
        //             // console.log('note started at '+inputEvent[3]);
        //             noteStarted = e[3];
        //         }
        //         return;
        //     } else if (e[0] !== 128 || noteStarted === 0) {
        //         // console.log('event not keyUp or noteStarted = 0');
        //         return;
        //     }
        //     if (e[0] === 128) {
        //         if (noteStarted === -1) noteStarted = startTime
        //         totalTime += e[3] - noteStarted
        //         noteStarted = -1
        //     }
        // });
        // if (noteStarted > 0 && within_window[within_window.length - 1]) {
        //     totalTime += endTime - noteStarted
        // }
        // console.log(totalTime, (endTime - startTime))
        // // return (totalTime > (endTime - startTime) / 4)
        // return (totalTime > 0)
    }

    midiNum2Note(midiNum) {
        return NOTE_NAMES[midiNum % 12] + Math.floor(midiNum / 12);
    }

    dur2Sec(dur) {
        return Tone.Time("1m").toSeconds()*dur;
    }

    setupPhrase(loopLen) {
        console.log('Setting up phrase...');
        if (this.state.phraseLoop) {
            this.state.phraseLoop.stop(0);
        }
        // phrase gets read from this.state.phrases
        // this automatically adds the loop to the transport
        const phraseLoop = new Tone.Loop(time => {
            // this.planRhythmSection(time)

            this.setState({
                loopNum: this.state.loopNum + 1
            })

            this.rhythmSectionRaw.start()
            
            // this.state.rhythmSectionRaw.play()
            if (this.state.phase === GUESS_PHASE) {
                console.log('Got ' + this.state.guess[0] + 'notes out of ' + this.state.guess[1] + ' right!');
                if (this.state.guess[0] === this.state.guess[1] || this.state.skipping || this.props.location.state.mode === 1) {
                    if (this.phraseIndex + 1 === this.phrases.length) {
                        this.setState({ results: true })
                        this.phraseIndex =  this.phraseIndex + 1
                        this.setState({
                            skipping: false
                        });
                        this.state.guessLog.push(this.state.guess)
                        this.state.phraseLoop.stop()
                        return;
                    }
                    this.phraseIndex =  this.phraseIndex + 1
                    this.setState({
                        skipping: false
                    });
                    this.state.guessLog.push(this.state.guess)
                }
            }


            if (this.state.phase === READY_PHASE || this.state.phase === GUESS_PHASE) {
                this.setState({ phase: LISTEN_PHASE })
                this.setState({ guess: [0, 0] })
                // showing only the first note
                let actualFirstKey = this.phrases[this.phraseIndex][0][0] + this.props.location.state.rhythmSection.key;
                setTimeout(() => {
                    this.setState({ firstKey: actualFirstKey })
                    this.keyboard.keyDown(actualFirstKey)
                }, this.dur2Sec(this.phrases[this.phraseIndex][0][1]) * 1000)
                setTimeout(() => this?.keyboard?.keyUp(actualFirstKey), this.dur2Sec(this.phrases[this.phraseIndex][0][2]) * 1000)
                setTimeout(() => this?.setState({ firstKey: null }), this.dur2Sec(4.0) * 1000)

                this.phrases[this.phraseIndex].forEach(
                    event => {
                        let actualKey = event[0] + this.props.location.state.rhythmSection.key;
                        this.synth.triggerAttackRelease(
                            this.midiNum2Note(actualKey), event[2], time + this.dur2Sec(event[1]));
                        setTimeout(() => {
                            this.setState({ guess: [this.state.guess[0], this.state.guess[1] + 1] })
                            this.ProgressBar.current.pulsate('purple')
                        }, this.dur2Sec(event[1]) * 1000)
                    });
            } else {
                this.setState({ phase: GUESS_PHASE })
                this.phrases[this.phraseIndex].forEach(
                    event => {
                        setTimeout(() => {
                            if (this.evaluateNoteGuess(event)) {
                                this.setState({
                                    guess: [this.state.guess[0] + 1, this.state.guess[1]]
                                })
                                this.ProgressBar.current.pulsate('green')
                            } else {
                                this.ProgressBar.current.pulsate('red')
                            }
                        }, (this.dur2Sec(event[1]) + this.dur2Sec(event[2]) + GUESS_WINDOW) * 1000)
                    });
                }
            this.inputMIDIEvents = []
        }, loopLen);
        // this.planRhythmSection(Tone.Transport.now()) // first loop
        phraseLoop.start(0);
        this.setState({
            phraseLoop: phraseLoop
        });
    }

    getNoteFromFFT(values) {
        let arr = Array.from(values)
        // let total = 0
        // let start_i, end_i
        // let LOWER_FREQ = 110, UPPER_FREQ = 3520
        // for (let i = 0; i < arr.length; i++) {
        //     let freq = this.fft.getFrequencyOfIndex(i)
        //     if (freq < LOWER_FREQ)
        //         continue
        //     if (freq > UPPER_FREQ) {
        //         if (!end_i) end_i = i
        //         continue
        //     }
        //     if (!start_i) start_i = i
        //     total += arr[i]
        // }
        // let mean = total / (end_i - start_i)
        // let var_total = 0
        // for (let i = start_i; i < end_i; i++) {
        //     var_total += (arr[i] - mean) * (arr[i] - mean)
        // }
        // let variance = var_total / ((end_i - start_i) - 1)
        // if (variance < 100) {
        //     return -1
        // }

        let max = Math.max(...arr)
        let argmax = arr.indexOf(max)
        let freq = this.fft.getFrequencyOfIndex(argmax)
        let note_freq = 55  // A1 freq
        let note_i = 33
        for (; note_i < 100; note_i++) {
            if (freq < note_freq) break
            note_freq *= Math.pow(2, 1/12)
        }
        let freq_above = note_freq
        let freq_below = freq_above / Math.pow(2, 1/12)
        if (Math.abs(freq_above / note_freq) > Math.abs(note_freq / freq_below)) {
            return note_i - 2
        } else {
            return note_i - 1
        }
    }

    setUpMic() {
        const fft = new Tone.FFT();
        this.fft = fft
        fft.smoothing = 0
        fft.size = 1024 * 16
        const mic = new Tone.UserMedia().connect(fft);
        mic.open().then(() => {
            console.log("mic open");
            setInterval(() => {
                let note = this.getNoteFromFFT(fft.getValue())
                if (note === -1) return
                if (this.state.currentNote === note) {
                    if (this.state.consecutiveCount > 5) {
                        this?.setState({
                            currentNote: note
                        })
                        let keyColorWhite = (this.state.phase === GUESS_PHASE) ? "#cd95e6" : "#F5DEFF"
                        let keyColorBlack = (this.state.phase === GUESS_PHASE) ? "#cd95e6" : "#473450"
                        this.inputMIDIEvents = [...this.inputMIDIEvents, [144, note, 127, Tone.Transport.now()], [128, note, 0, Tone.Transport.now() + 0.1]]
                        this.keyboard.keyDown(note, keyColorWhite, keyColorBlack)
                        this.inputMIDIEvents = [...this.inputMIDIEvents, [144, note + 12, 127, Tone.Transport.now()], [128, note + 12, 0, Tone.Transport.now() + 0.1]]
                        this.keyboard.keyDown(note + 12, keyColorWhite, keyColorBlack)
                        this.inputMIDIEvents = [...this.inputMIDIEvents, [144, note - 12, 127, Tone.Transport.now()], [128, note - 12, 0, Tone.Transport.now() + 0.1]]
                        this.keyboard.keyDown(note - 12, keyColorWhite, keyColorBlack)
                        this.inputMIDIEvents = [...this.inputMIDIEvents, [144, note + 12, 127, Tone.Transport.now()], [128, note + 12, 0, Tone.Transport.now() + 0.1]]
                        this.keyboard.keyDown(note + 24, keyColorWhite, keyColorBlack)
                        this.inputMIDIEvents = [...this.inputMIDIEvents, [144, note - 12, 127, Tone.Transport.now()], [128, note - 12, 0, Tone.Transport.now() + 0.1]]
                        this.keyboard.keyDown(note - 24, keyColorWhite, keyColorBlack)
                    } else {
                    this.setState({ consecutiveCount: this.state.consecutiveCount + 1 })
                    }
                } else {
                    this?.keyboard?.keyUp(this?.state.currentNote)
                    this?.keyboard?.keyUp(this?.state.currentNote + 12)
                    this?.keyboard?.keyUp(this?.state.currentNote - 12)
                    this?.keyboard?.keyUp(this?.state.currentNote + 24)
                    this?.keyboard?.keyUp(this?.state.currentNote - 24)
                    this.setState({ currentNote: note, consecutiveCount: this.state.consecutiveCount + 1 })
                }
            }, 10);
        }).catch(e => {
            // promise is rejected when the user doesn't have or allow mic access
            console.log("mic not open");
        });
    }

    async initialize(inputMode) {
        this.inputMode = inputMode
        if (inputMode === 2) this.setUpMic()
        await Tone.start();
        if (inputMode === 1) this.requestMIDI()
        // const synth = new Tone.PolySynth(Tone.Synth).toDestination();
        const synth = new Tone.Sampler({
            urls: {
                A2: "A1.mp3",
                A3: "A2.mp3",
            },
            baseUrl: "https://tonejs.github.io/audio/casio/",
            onload: () => {
                // synth.triggerAttackRelease(["C1", "E1", "G1", "B1"], 0.5);
            }
        }).toDestination()
        Tone.context.lookAhead = 0;
        this.setState({
            initialized: true
        });
        this.synth = synth
        Tone.Transport.bpm.value = this.props.location.state.rhythmSection.tempo;
        // this.setupMIDIListen(this.state.loopLen);
        this.setupPhrase(this.props.loopLen);
        // this.setuprhythmSection(this.state.loopLen);
        this.rhythmSectionRaw.loop = true;
        this.rhythmSectionRaw.start()
        Tone.Transport.start();
    }

    requestMIDI() {
        navigator.requestMIDIAccess()
            .then((midiAccess) => {
                console.log("MIDI access granted!");
                console.log(midiAccess)
                this.setupMIDI(midiAccess);
            }, (message) => {
                console.log("MIDI access denied. - " + message);
            }
		);
    }

    playNote(numKey) {
	    this.synth.triggerAttackRelease(NOTE_NAMES[numKey % 12] + Math.floor(numKey / 12), "8n");
    }

    setupMIDI(midi) {
        let midiHandler = (event) => {
            // if (event.data[0] !== 254)
                // console.log("MIDI message received at timestamp " + Tone.now() + "[" + event.data + "]: ");
            if (event.data[0] === 144) {
                this.keyboard.keyDown(event.data[1]);
                this.playNote(event.data[1]);
                this.inputMIDIEvents = [...this.inputMIDIEvents, [...event.data, Tone.Transport.now()]]
            }
            if (event.data[0] === 128) {
                this?.keyboard.keyUp(event.data[1]);
                this.inputMIDIEvents = [...this.inputMIDIEvents, [...event.data, Tone.Transport.now()]]
            }
        };
        midi.inputs.forEach((entry) => entry.onmidimessage = midiHandler);
    }

    render() {
        // Using pixels to size circularProgress (thickness). Probably should use dpi instead
        return (
	    <div>
            {/* {this.fft && <FrequencyChart values={this.state.fft_values} fft={this.fft}/>} */}
	        {!this.state.initialized &&
	         <InputSelectionOverlay
                loaded={this.state.loadedPhrases && this.state.loadedRhythmSection}
                onClick={this.initialize.bind(this)}/>}
            <LooperComponent
                phrases={this.phrases}
                results={this.state.results}
                guessLog={this.state.guessLog}
                guess={this.state.guess}
                user={this.props.user}
                loopNum={this.state.loopNum}
                phraseIndex={this.phraseIndex}
                progressBarRef={this.ProgressBar}
                phase={this.state.phase}
                initialized={this.state.initialized}
                loopLen={this.props.loopLen}
                skipping={this.state.skipping}
                userData={this.props.location.state.userData}
                mode={this.props.location.state.mode}
                rhythmSection={this.props.location.state.rhythmSection}
                cleanup={() => this.rhythmSectionRaw.dispose()}/>
            <div className='fixed top-12 left-12 w-[400px] h-20 bg-white border-4 border-grey-400 shadow-xl text-7xl font-bold items-center rounded-full
                            flex p-4 justify-between'>
                    <div className='flex w-[200px] justify-between items-center pr-8 border-r-2 border-grey-400 text-gray-700'>
                        <div className='flex items-center justify-between bg-gray-900 p-1 rounded-full shadow-xl mr-4'>
                            <div className={`${(this.state.phase===LISTEN_PHASE && !this.state.skipping) ? "bg-red-500" : "bg-red-900" } rounded-full overflow-hidden mr-2 w-12`}>
                                <EarIcon className='w-10 h-10 m-1'/>
                            </div>
                            <div className={`${(this.state.phase===GUESS_PHASE && !this.state.skipping) ? "bg-green-500" : "bg-green-900" } rounded-full overflow-hidden w-12`}>
                                <MIDIIcon className='w-10 h-10 m-1'/>
                            </div>
                        </div>
                        <SVGButton onClick={() =>
                                this.setState({
                                    skipping: true
                                })} componentHover={<SkipIconFill/>} componentNotHover={<SkipIconStroke/>}/>
                    </div>
                    <div className='text-2xl text-bold text-gray-400 w-24'>{this.phraseIndex}/{10}</div>
            </div>
	      <Keyboard
                phase={this.state.phase}
                firstKey={48}
                lastKey={84}
                playNote={(note) => (this.inputMode !== 2) && this.playNote(note)}
                onRef={ref => {this.keyboard = ref;}}
                firstIndicate={this.state.firstKey}/>
	    </div>
        );
    }
}

function LocationWrapper(props) {
    const location = useLocation()
    return <LooperLogic location={location} {...props} /> 
}

export default LocationWrapper;
