import React from 'react';
import * as Tone from 'tone';
import CircularProgressBar from './CircularProgressBar';
import '../components/CircularProgressBar.css'
import ResultsComponent from './ResultsComponent';
import '../components/ResultsComponent.css';

// const READY_PHASE = 0;
// const LISTEN_PHASE = 1;
// const GUESS_PHASE = 2;

const LooperComponent = props => {


    
    return (
    <div style={{ background: '#88888880', border: 'none', position: 'fixed', left: 0, height: '100vh', top: 0, width: '100vw' }}>
        {props.results &&
        <ResultsComponent mode={props.mode} phrases={props.phrases} guesses={props.guessLog} user={props.user} userData={props.userData} cleanup={props.cleanup} rhythmSection={props.rhythmSection}/>}
        <div style={{ 
            marginRight: 30, 
            position: 'relative', 
            height: '90vh' ,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            }}>
            <div style={{ 
                position: 'relative', 
                display: 'flex', 
                width: '100%', 
                height: '100%', 
                ...(props.results) ? { animation: '1s ease 0s 1 normal forwards running slideAway' } : {}, 
                }}>
                <CircularProgressBar
                    key={props.loopNum}
                    ref={props.progressBarRef}
                    initialized={props.initialized}
                    size={600}
                    trackWidth={32}
                    indicatorWidth={32}
                    loopLen={Tone.Time(props.loopLen).toSeconds()}
                    skipping={props.skipping}
                    guess={props.guess}
                    style={{
                        position: 'absolute',
                        top: "50%",
                        left: '50%',
                        transform: "translate(-50%, -50%)",
                        zIndex: 3,
                        color: 'white',
                        marginRight: 30,
                        width: 600,
                        height: 600,
                        stroke: 'white'
                    }}/>
            </div>
        </div>
        {/* <div
            style={{ 
                position: 'absolute',
                display: 'flex',
                top: 0,
                right: 50,
                width: 160,
                height: 150
            }}>
            
            <div style={{ position: 'relative', width: 30 }}>
                <h3 style={{
                    position: 'absolute',
                    left: 0,
                    right: 0,
                    bottom: 0,
                    margin: 'auto',
                    background: '#545454',
                    color: '#12e100',
                    inlineSize: 'min-content'
                }}>{(props.phrases) ? props.phraseIndex + "/" + props.phrases.length : "0/0"}</h3>
            </div>
        </div> */}
    </div>
    );
}

export default LooperComponent;