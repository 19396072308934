import { Component } from 'react';
import { Navigate } from 'react-router-dom';
import '../res/styles/MenuBackground.css';
import { getUserInfo } from '../api/APICalls';
import { createUserData } from '../graphql/mutations';
import { generateClient } from 'aws-amplify/api';
import { fetchUserAttributes } from 'aws-amplify/auth';
import { listRhythmSections } from '../graphql/queries';
import * as Tone from 'tone';
import { getUrl } from 'aws-amplify/storage';
import ProfBarComponent from '../components/ ProfBarComponent';

// const NUM2KEY = ['C', 'Db', 'D', 'Eb', 'E', 'F', 'Gb', 'G', 'Ab', 'A', 'Bb', 'B'];

export function MenuBackground (props) {
    const stripeNum = 20;
    return (
	<div style={{
	    position: 'fixed',
	    zIndex: 1,
	    left: '-50%',
	    top: '-50%',
	    right: '-50%',
	    bottom: '-50%',
	    display: 'flex',
	    alignItems: 'center',
	    justifyContent: 'center' }}>
          <div className="MenuBackground">
            {Array.from({ length: stripeNum }, (_, i) =>
		<div
		  key={i}
		  style={{
		      position: 'absolute',
		      zIndex: 2,
		      top: 0,
		      bottom: 0,
		      left: 100/stripeNum*i+'%',
		      width: 100/stripeNum/2+'%',
		      background: '#eeeeee',
		  }}/>
	    )}
          </div>
	</div>
    );
}

class MenuScreen extends Component {
	player = null

	constructor(props) {
		super()
		this.state = {
			redirect: 0,
			userData: { nickname: "ゲスト", rating: 100000, imageKey: "boy_5664044" },
			rhythmSections: [{ name: 'Random', key: 0, tempo: 120 }],
			rhythmSectionIndex: 0
		}
	}

	componentDidMount() {
		console.log(this.props.user.username)
		if (this.props.user.userId !== "guest") {
			getUserInfo(this.props.user.username, async userData => {
				if (userData) this.setState({userData})
				else {
					const userAttributes = await fetchUserAttributes();
					const client = generateClient()
					let userDataToSet = {userId: this.props.user.username, rating: 100000, nickname: 'ニックネーム', imageKey: "boy_5664044" }
					await client.graphql({ query: createUserData, variables: { input: userDataToSet }})
					this.userCreated = true
					this.setState({ userData: userDataToSet })
				}
			})
		}
		this.getRhythmSections()
	}

	async getRhythmSections() {
		const client = generateClient()
		let rhythmSections = await client.graphql({ query: listRhythmSections });
		// console.log(rhythmSections.data)
		this.setState({ rhythmSections: [{ name: '-RANDOM-', key: 0, tempo: 120 }].concat(rhythmSections.data.listRhythmSections.items) })
	}

	async getRhythmSectionRaw(index) {
        try {
            const result = await getUrl({
                key: "rhythm_section/" + this.state.rhythmSections[index].name + ".wav",
                options: {
                  validateObjectExistence: true, // defaults to false
                }
            })
            // console.log('signed URL: ', result.url.href);
            // console.log('URL expires at: ', result.expiresAt);
			// this.player.stop()
            this.player = new Tone.Player(result.url.href).toDestination()
			this.player.autostart = true
			this.player.loop = true
        } catch (error) {
            console.error(error)
        }
        // this.rhythmSectionRaw = new Tone.Player("https://loop7th-storage-fb0118d5104611-main.s3.ap-northeast-1.amazonaws.com/sample.wav")
        // this.rhythmSectionRaw.autostart = true
    }

	render() {
		if (this.state.redirect !== 0) {
			if (this.player) this.player.stop()
			let index = (this.state.rhythmSectionIndex === 0 || this.state.redirect === 1) ? Math.floor(Math.random() * (this.state.rhythmSections.length - 1)) + 1 : this.state.rhythmSectionIndex;
			return(
				<Navigate
				to='/loopingRange'
				state={{
					rhythmSection: this.state.rhythmSections[index],
					mode: this.state.redirect,
					userData: this.state.userData
				}}/>
			);
		}
		return (
		<div>
			<MenuBackground />
			<div className='w-screen h-screen pb-[160px] flex justify-center items-center'>
				<div className='relative w-[500px] h-[500px] mr-5 bg-pr border-4 border-pr_dk shadow-xl text-7xl font-bold items-end z-50 rounded-l-[150px] rounded-r-3xl transition ease-in-out  hover:-translate-x-[25px] hover:scale-110'
						onClick={() => {this.setState({ redirect: 1}); console.log('redirecting');}}>
						<div style={{ paddingTop: 50, textDecoration: 'underline', color: 'white', textAlign: 'end' }}>Challenge&nbsp;&nbsp;</div>
						<div className='text-pr_dk' style={{ fontSize: 32, lineHeight: 1.1, textAlign: 'end', paddingTop: 50, paddingRight: 24, textDecoration: 'unset', whiteSpace: 'pre-wrap' }}>
							{'限界を試そう！\n１つのフレーズあたり\nチャンスは１回'}</div>
						<div style={{ color: 'white', position: 'absolute', bottom: 24, right: 0, paddingRight: 24, whiteSpace: 'pre-wrap' }}>
							<div style={{ fontSize: 24, textAlign: 'end', marginRight: 8, marginBottom: 8 }}>
								{'あなたのレーティング'}</div>
							<div className='bg-pr_dk p-2 mr-2 mb-2'>
								{(this.props.user.userId === "guest" || this.state.userData.nickname === 'ゲスト') ? <div className='text-gray-400'>-------</div> : this.state.userData?.rating}</div></div>
					</div>
				<div className='relative w-[500px] h-[500px] ml-5 bg-sc border-4 border-sc_dk shadow-xl text-7xl font-bold items-end z-50 rounded-r-[150px] rounded-l-3xl  transition ease-in-out  hover:translate-x-[25px] hover:scale-110'
					onClick={() => {this.setState({ redirect: 2}); console.log('redirecting');}}
					onMouseEnter={() => { 
						if (this.player) this.player.volume.value = 0
					}}
					onMouseLeave={() => { if (this.player) this.player.volume.value = -10}}>
					<div style={{ paddingTop: 50, textDecoration: 'underline', color: 'white' }}>&nbsp;&nbsp;Practice</div>
					<div className='text-sc_dk' style={{ fontSize: 32, lineHeight: 1.1, textAlign: 'start', paddingTop: 50, paddingLeft: 24, textDecoration: 'unset', whiteSpace: 'pre-wrap', width: '70%' }}>
						{'音感を鍛えよう！\n正解するまで何回でも'}</div>
					<div style={{ fontSize: 36, display: 'flex', position: 'absolute', bottom: 24, left: 24, alignItems: 'baseline', color: 'white' }}>
						<div className='relative flex items-center' style={{ fontSize: 24 }}>
							<div className='bg-white rounded-full text-sc p-2 mr-1 shadow-xl cursor-pointer select-none'
								onClick={async e => { 
									e.stopPropagation(); 
									if (this.player) this.player.stop()
									this.setState({ rhythmSectionIndex: (this.state.rhythmSectionIndex - 1 + this.state.rhythmSections.length) % this.state.rhythmSections.length }) 
									await this.getRhythmSectionRaw.bind(this)((this.state.rhythmSectionIndex - 1 + this.state.rhythmSections.length) % this.state.rhythmSections.length)
								}}>{'<'}</div>
							<div className='bg-sc_dk w-[300px]' style={{ fontSize: 48, padding: 8, margin: 8, textAlign: 'center' }}>
								{this.state.rhythmSections[this.state.rhythmSectionIndex]['name']}</div>
							<div className='bg-white rounded-full text-sc p-2 ml-1 shadow-xl cursor-pointer select-none'
								onClick={async e => { 
									e.stopPropagation(); 
									if (this.player) this.player.stop()
									this.setState({ rhythmSectionIndex: (this.state.rhythmSectionIndex + 1) % this.state.rhythmSections.length }) 
									await this.getRhythmSectionRaw.bind(this)((this.state.rhythmSectionIndex + 1) % this.state.rhythmSections.length)
								}}>{'>'}</div>
						</div>
							{/* in
						<div style={{ position: 'relative', fontSize: 24 }}>
							<div className='absolute -top-2 w-full text-center height-2 leading-none select-none cursor-pointer'
								onClick={e => { e.stopPropagation(); this.setState({ key: (this.state.key + 1) % 12 }) }}>
								<div className='text-sc_dk' style={{ background: 'white', width: 36, margin: 'auto', borderRadius: 100 }}>+</div></div>
							<div className='bg-sc_dk' style={{ fontSize: 48, padding: 8, margin: 8, width: 100, textAlign: 'center' }}>
								{NUM2KEY[this.state.key]}</div>
							<div className='absolute -bottom-2 w-full text-center height-2 leading-none select-none cursor-pointer'
								onClick={e => { e.stopPropagation(); this.setState({ key: (this.state.key - 1) % 12 }) }}>
								<div className='text-sc_dk' style={{ background: 'white', width: 36, margin: 'auto', borderRadius: 100 }}>-</div></div>
						</div>
						at
						<div style={{ position: 'relative', fontSize: 24 }}>
							<div className='absolute -top-2 w-full text-center height-2 leading-none select-none cursor-pointer'
								onClick={e => { e.stopPropagation(); this.setState({ tempo: this.state.tempo + 10 }) }}>
								<div className='text-sc_dk' style={{ background: 'white', width: 36, margin: 'auto', borderRadius: 100 }}>+</div></div>
							<div className='bg-sc_dk' style={{ fontSize: 48, padding: 8, margin: 8, width: 120, textAlign: 'center' }}>
								{this.state.tempo}</div>
							<div className='absolute -bottom-2 w-full text-center height-2 leading-none select-none cursor-pointer'
								onClick={e => { e.stopPropagation(); this.setState({ tempo: this.state.tempo - 10 }) }}>
								<div className='text-sc_dk' style={{ background: 'white', width: 36, margin: 'auto', borderRadius: 100 }}>-</div></div>
							</div>
							bpm*/}

					</div> 
				</div>
			</div>
			<ProfBarComponent userData={this.state.userData} user={this.props.user} setManualLogin={this.props.setManualLogin}/>
			{/* <div className='bg-white shadow-xl rounded-full border-4 border-gray-400 fixed bottom-10 right-10 flex items-center p-4 z-50'>
				{this.props.user.userId === "guest" ? <LoginIcon className='cursor-pointer' onClick={this.props.signOut}/> : <LogoutIcon className='cursor-pointer' onClick={this.props.signOut}/>}
			</div> */}
		</div>
		);
	}
}


export default MenuScreen;
