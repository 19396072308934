import { list } from "aws-amplify/storage";
import ProfPicComponent from "./ProfPicComponent"
import { useEffect, useRef, useState } from "react";
import { updateUserData } from "../graphql/mutations";
import { generateClient } from "aws-amplify/api";

import { ReactComponent as LogoutIcon } from '../res/img/logout.svg'
import { ReactComponent as LoginIcon } from '../res/img/login.svg'

import { signOut } from 'aws-amplify/auth';

const client = generateClient()

function useOutsideAlerter(ref, onClickedOut) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          onClickedOut()
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

async function updateUserImgKey(key, userData, setNewUserData) {
    const result = await client.graphql({ query: updateUserData, variables: { input: {id: userData.id, imageKey: key, _version: userData._version } }})
    setNewUserData(result.data.updateUserData)
}

async function updateNickname(nickname, userData, setNewUserData) {
  console.log(userData)
  const result = await client.graphql({ query: updateUserData, variables: { input: {id: userData.id, nickname: nickname, _version: userData._version } }})
  console.log(result)
  setNewUserData(result.data.updateUserData)
}

function ProfBarComponent(props) {
    const [expanding, setExpanding] = useState(false)
    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef, () => setExpanding(false));
    const [updatedUserData, setUpdatedUserData] = useState()

    const [profImgKeys, setProfImgKeys] = useState([])

    useEffect(() => {
        if (profImgKeys.length === 0) {
            try {
                list({
                  prefix: 'prof_images/'
                }).then(result => {
                    setProfImgKeys(result.items.map(element => element.key.slice(12, element.key.length - 4)));
                })
              } catch (error) {
                console.log(error);
              }
        }
    }, [expanding])

    return (
        // <div className='bg-white shadow-xl rounded-[50px] border-4 border-gray-400 fixed bottom-10 transition-all left-10 p-4 z-40 cursor-pointer'>
        //     <div className="flex items-center">
        //         <ProfPicComponent className='rounded-full' imgKey={props.userData?.imageKey}/>
        //         <div className='relative h-full' onMouseEnter={() => setHovering(true)} onMouseLeave={() => setHovering(false)}>
        //             <input defaultValue={props.userData?.nickname} 
        //                 className='flex-1 ml-8 h-full text-4xl text-gray-700 font-bold z-50 appearance-none p-4 pr-0 mr-4'/>
        //             <div className='mr-4 border-1 border-gray-400 absolute right-0 top-0 mt-1 pointer-events-none'>
        //                 {hovering ? <EditIconFill className="w-16 h-16"/> : <EditIcon className="w-16 h-16"/>}
        //             </div>
        //         </div>
        //     </div>
        //     {expanding && <div className="w-24 h-24"></div>}
        // </div>
        <div className='bg-white shadow-xl rounded-[50px] border-4 border-gray-400 fixed bottom-10 transition-all left-10 p-4 z-40'>
             <div className="flex items-center relative">
                 <ProfPicComponent 
                    className='rounded-full cursor-pointer' 
                    imgKey={updatedUserData ? updatedUserData.imageKey : props.userData?.imageKey}
                    onClick={() => setExpanding(!expanding)}/>
                {expanding && <div ref={wrapperRef} className="absolute overflow-scroll bottom-0 rounded-full shadow-xl w-[80px] h-[200px] bg-white z-50">
                    {profImgKeys.map(key =>
                    <ProfPicComponent 
                        key={key}
                        className='rounded-full cursor-pointer' 
                        imgKey={key}
                        onClick={() => {
                            setExpanding(false)
                            updateUserImgKey(key, updatedUserData ? updatedUserData : props.userData, setUpdatedUserData)
                        }}/> )}
                    </div>}
                <input type="text"
                  contentedible='true'
                  className='flex-1 h-full text-4xl text-gray-700 font-bold z-50 appearance-none p-4 ml-2 pr-8 mr-2 border-solid border-r-2 w-60'
                  value={updatedUserData ? updatedUserData.nickname : props.userData.nickname}
                  onChange={e => {
                    updateNickname(e.target.value, updatedUserData ? updatedUserData : props.userData, setUpdatedUserData)
                    } }/>
                {props.user.userId === "guest" ? <LoginIcon className='cursor-pointer w-20' onClick={props.setManualLogin}/> : <LogoutIcon className='cursor-pointer w-20' onClick={async () => {await signOut(); window.location.reload();  }}/>}
            </div>
        </div>
    )
}

export default ProfBarComponent