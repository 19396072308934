import { Component, useEffect } from 'react';

const WHITE = false, BLACK = true;
const KEY_LAYOUT = [ WHITE, BLACK, WHITE, BLACK, WHITE, WHITE, BLACK, WHITE, BLACK, WHITE, BLACK, WHITE ];

const WHITE_DISABLED = '#dddddd'
const BLACK_DISABLED = '#666666'

class Keyboard extends Component {
    numWhiteKeys = 0

    constructor(props) {
        super(props);
        for (let i = props.firstKey; i <= props.lastKey; i++)
            if (!KEY_LAYOUT[i % 12]) this.numWhiteKeys++;
    }

	componentDidMount() {
		this.props.onRef(this);
	}

	componentWillUnmount() {
		this.props.onRef(undefined);
	}
	
	keyDown (key, colorWhite="#cd95e6", colorBlack="#cd95e6") {
        if (key > this.props.lastKey || key < this.props.firstKey) {
            // console.log('Key out of range of on-screen keyboard');
            return;
        }
		let keyComp = document.getElementById('key' + key);
        keyComp.style.background = (KEY_LAYOUT[key % 12]) ? colorBlack : colorWhite
		this.props.playNote(key);
	}

    keyUp (key) {
        if (key > this.props.lastKey || key < this.props.firstKey) {
            // console.log('Key out of range of on-screen keyboard');
            return;
        }
		let keyComp = document.getElementById('key' + key);
        keyComp.style.background = (KEY_LAYOUT[key % 12]) ? 'black' : 'white';
        if (this.props.phase !== 2) keyComp.style.background = (KEY_LAYOUT[key % 12]) ? BLACK_DISABLED : WHITE_DISABLED;
	}
	
    renderWhiteKey (key, disabled=false) {
        return (
            <button
              key={key}
              id={'key' + key}
              onMouseDown={() => this?.keyDown(key)}
              onMouseUp={() => this?.keyUp(key)}
              onMouseLeave={() => this?.keyUp(key)}
              style={{
                  background: disabled ? WHITE_DISABLED : 'white',
                  borderRight:  'solid',
                  flex: 1, position: 'relative' }}><div className='absolute bottom-0 w-full'>{this.props.firstIndicate === key ? 1 : ''}</div></button>
        );
    }
    
    renderBlackKey (key, disabled=false) {
        return (
            <div key={key} style={{ flex: 1, display: 'flex', justifyContent: 'center' }} >
              <button
                id={'key' + key}
                onMouseDown={() => this?.keyDown(key)}
                onMouseUp={() => this?.keyUp(key)}
                onMouseLeave={() => this?.keyUp(key)}
                style={{
                    background: disabled ? BLACK_DISABLED : 'black', 
                    marginTop: -4, borderRadius: 4,
                    height: '120%',
                    width: '70%', color: "white"
                }}>{this.props.firstIndicate === key ? 1 : ''}</button>
            </div>
        );
    }
    
	render() {
        let whiteKeys = [], blackKeys = [];
        for (let i = this.props.firstKey; i <= this.props.lastKey; i++) {
            if (KEY_LAYOUT[i % 12]) {
                blackKeys.push(this.renderBlackKey(i, this.props.phase !== 2));
            } else {
                whiteKeys.push(this.renderWhiteKey(i, this.props.phase !== 2));
                if (!KEY_LAYOUT[(i - 1) % 12])
                    blackKeys.push(
						<div key={'space'+i} style={{ flex: 1 }} />
                    ); // spacing between black keys
            }
        }
        
		return (
            <div>
			  <div style={{
                  display: 'flex',
                  position: 'fixed',
                  top: '90%',
                  bottom: 0,
                  left: 0,
                  right: 0}}>
			    {whiteKeys}
			  </div>
              <div style={{
                  display: 'flex',
                  position: 'fixed',
                  left: `-${1 / this.numWhiteKeys / 2 * 100}%`,
                  right:`${1 / this.numWhiteKeys / 2 * 100}%`,
                  top: '90%',
                  bottom: '5%'}}>
                   {blackKeys}
			     </div>
            </div>
		);
	}
}

export default Keyboard;
