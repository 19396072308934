import { useState } from "react";



function SVGButton (props) {
    const [hover, setHover] = useState(false)
    return (
        <div onMouseEnter={() => { setHover(true); console.log('hover') }} onMouseLeave={() => setHover(false)} onClick={props.onClick}>
            {hover ? 
            props.componentHover: props.componentNotHover} 
        </div>
    );
}

export default SVGButton